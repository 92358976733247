<template>
  <span v-if="value" class="loading"></span>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.loading::after {
  display: inline-block;
  animation: dotty steps(1,end) 1s infinite;
  content: '';
  width: 2em;
}

@keyframes dotty {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
}
</style>
