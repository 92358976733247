import { mapState } from 'vuex';
import { UNIT_SYSTEMS, defaultSystem } from '@/store/modules/user';
import { convertUnitNoSymbol, unitSymbolProvider } from '@/filters/units';

export default {
  name: 'DetailsTile',
  props: {
    content: {
      type: Object,
      required: true,
    },
    convertOptions: {
      type: Object,
      default: () => ({
        noValueFallback: '-',
        fractionalDigits: 1,
      }),
    },
  },
  computed: mapState({
    selectedUnitsSystem: (state) => state.user.selectedUnitsSystem || UNIT_SYSTEMS[defaultSystem],
    convertedValue() {
      return convertUnitNoSymbol(
        this.content.value,
        this.content.enum.type,
        this.selectedUnitsSystem.system,
        this.content.convertOptions || this.convertOptions,
      );
    },
    unit() {
      return unitSymbolProvider(this.content.enum.type, this.selectedUnitsSystem.system, 0);
    },
    title() {
      return this.content.enum.name;
    },
  }),
};
