import VideoPlayer from '@/components/ui/players/VideoPlayer.vue';
import { video } from '@/services/ability';
import VideoDetails from './VideoDetails.vue';

export default {
  name: 'VideoBox',

  components: {
    VideoPlayer,
    VideoDetails,
  },

  inject: ['context'],

  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    thumbUrl: {
      type: String,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    close: {
      type: Function,
      default: () => { },
    },

    vid: {
      type: [Number, String],
      default: '',
    },
    deletingVideo: {
      type: Boolean,
    },
    videoIdToDelete: {
      type: Number,
    },

    disableTableLink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      deleteVideoId: undefined,
      deleteVideoLoading: false,
    };
  },

  computed: {
    media() {
      return this.content.media || [];
    },
    currentMedia() {
      const found = this.media.find(vid => vid.id === Number.parseInt(this.vid, 10));

      const caslVideo = video(found || { /* fallback video object */});

      return caslVideo;
    },
    actionsCount() {
      return this.actions.length;
    },
  },

  methods: {
    contentChange(newContent, oldContent) {
      const { media } = newContent;
      const filtered = media?.filter(m => m.id == this.vid);
      const mediaFromUrlWasDeleted = filtered?.length === 0;

      if (mediaFromUrlWasDeleted) {
        if (media?.length) {
          this.$router.push({ params: { vid: media[0].id } });
        } else {
          this.$router.push({ params: { action: null, vid: null } });
        }
      }
    },
  },

  watch: {
    content: {
      handler: 'contentChange',
    },
  }
};
