import { Enums } from '@flightscope/baseball-stats';
import get from 'lodash/get';
import { utcToLocal } from '@/utils/date_n_time';
import HasPlayers from '@/components/mixins/HasPlayers';
import PlayerProfileLink from '@/components/player/PlayerProfileLink.vue';
import { getActionTitle } from '@/utils/formatters';
import { sanitizeGuid } from '@/utils/helpers';
import DetailsTile from './DetailsTile.vue';
import { enumerate } from '@/filters/enums';
import {session} from '@/services/ability';

export default {
  name: 'VideoDetails',

  inject: ['shareEnabled'],

  mixins: [HasPlayers],

  props: {
    actionId: {
      type: [String,Number],
    },
    results: {
      type: Object,
      required: true,
    },
    video: {
      type: Object,
    },
    deletingVideo: {
      type: Boolean,
    },

    disableTableLink: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DetailsTile,
    PlayerProfileLink,
  },

  data() {
    return {
      shared: false,
      sharing: false,
      shareClient: null,
      uploading: false,
    };
  },

  asyncComputed: {
    batter: {
      async get() {
        let { pitch, hit } = this.results;
        let batter = pitch?.RelatedBatterTagID || hit?.TagID;
        if (batter) {
          batter = await this.PlayerRepo.get(batter);

          return batter;
        }
      },
    },
    pitcher: {
      async get() {
        let { pitch, hit } = this.results;
        let pitcher = pitch?.TagID || hit?.RelatedPitcherTagID;
        if (pitcher) {
          pitcher = await this.PlayerRepo.get(pitcher);

          return pitcher;
        }
      },
    },
  },

  computed: {
    canReadSession() {
      return this.$can('read', session({}));
    },
    pitchResultTiles() {
      if (!this.results.pitch) {
        return [];
      }

      const dataKey = Enums.ResultData.Data.key;
      return [
        {
          enum: Enums.PitchData.Speed,
          value: this.results.pitch[dataKey][Enums.PitchData.Speed.key],
        },
        {
          enum: Enums.PitchData.Spin,
          value: this.results.pitch[dataKey][Enums.PitchData.Spin.key],
          convertOptions: {
            noValueFallback: '-',
            fractionalDigits: 0,
          }
        },
        {
          enum: Enums.PitchReprocessed.PitchSpinTiltR,
          value: this.results.pitch[dataKey][Enums.PitchReprocessed.PitchSpinTiltR.key],
        },
        {
          enum: Enums.PitchData.BreakH,
          value: this.results.pitch[dataKey][Enums.PitchData.BreakH.key],
        },
        {
          enum: Enums.PitchData.BreakVInd,
          value: this.results.pitch[dataKey][Enums.PitchData.BreakVInd.key],
        },
        {
          enum: Enums.PitchData.Extension,
          value: this.results.pitch[dataKey][Enums.PitchData.Extension.key],
        },
      ];
    },
    hitResultTiles() {
      if (!this.results.hit) {
        return [];
      }

      const dataKey = Enums.ResultData.Data.key;
      return [
        {
          enum: Enums.HitData.ExitSpeed,
          value: this.results.hit[dataKey][Enums.HitData.ExitSpeed.key],
        },
        {
          enum: Enums.HitData.LaunchV,
          value: this.results.hit[dataKey][Enums.HitData.LaunchV.key],
        },
        {
          enum: Enums.HitData.CarryDistance,
          value: this.results.hit[dataKey][Enums.HitData.CarryDistance.key],
        },
      ];
    },
    actionTitle() {
      return getActionTitle({ results: this.results });
    },
    formattedDate() {
      const result = this.results?.pitch || this.results?.hit;
      const date = result.CreateDate;
      const localDate = new Date(utcToLocal(date));
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'medium' }).format(localDate);
    },
    items() {
      let dataKey = Enums.ResultData.Data.key;

      let out = [];

      let { pitch, hit } = this.results;

      let players = [];

      if (this.batter) {
        players.push({ label: 'Batter', value: this.batter.FormattedName });
      }

      if (this.pitcher) {
        players.push({ label: 'Pitcher', value: this.pitcher.FormattedName });
      }

      if (pitch) {
        let pc = get(pitch, [dataKey, Enums.ScoringData.PitchCall.key]);
        let par = get(pitch, [dataKey, Enums.ScoringData.PlateAppearanceResult.key]);
        let pr = get(pitch, [dataKey, Enums.PitchData.Result.key]);

        if ( pc || par ) {
          pc = enumerate(pc, Enums.PitchCall);
          par = enumerate(par, Enums.PlateAppearanceResult);

          out = [
            { label: Enums.ScoringData.PitchCall.short, value: pc },
            { label: Enums.ScoringData.PlateAppearanceResult.short, value: par },
          ];

        } else if (pr) {
          pr = enumerate(pr, Enums.PitchResult);

          out = [
            { label: Enums.PitchData.Result.short, value: pr },
          ];
        }

      }

      if (hit) {
        let bbt = get(hit, [dataKey, Enums.PitchReprocessed.BattedBallTypeR]);

        if (bbt) {
          bbt = enumerate(bbt, Enums.BattedBallType);
          out = [
            { label: Enums.PitchReprocessed.BattedBallTypeR.short, value: bbt },
          ];
        }
      }

      return [
        ...players,
        ...out,
        { label: 'Date', value: this.formattedDate },
      ];
    },
  },

  methods: {
    goToDataTable() {
      const hitResult = this.results.hit;
      const pitchResult = this.results.pitch;

      let sessionID = null;
      let guid = null;
      if (pitchResult) {
        sessionID = pitchResult.SessionID;
        guid = pitchResult.Data.GUID;
      } else if (hitResult) {
        sessionID = hitResult.SessionID;
        guid = hitResult.Data.GUID;
      } else {
        return;
      }

      if (guid && sessionID) {
        this.$router.push({
          name: 'session.table',
          hash: `#${sanitizeGuid(guid)}`,
          params: { id: sessionID, type: 'Game' }
        });
      }
    },
  },
};
