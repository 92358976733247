import Vue from 'vue';
import { Client } from '@/services';
import { Services } from '@/store/actions/api';
import { jsonToFormData } from '../../../utils/http-common';
import { ErrorType, WsErrorType } from '../../../constants';
import { captureException } from '../../../plugins/sentry';

export default class TwitterShare {
  constructor() {
    this.client = Client;
    this.authorized = false;

    const { key: service, methods } = Services.Twitter;

    this.service = service;
    this.methods = methods;
  }

  async getAuthorizeUrl(route) {
    const { key: method, type } = this.methods.RequestOauth;
    let { params: payload } = this.methods.RequestOauth;

    const params = {
      service: this.service,
      method,
    };

    payload.AutoRedirect = false;
    payload.RedirectUrl = route;

    const data = jsonToFormData(payload);

    try {
      const { data: authResponse, error } = await this.client({ params, data, method: type });
      Vue.$log.debug(authResponse);

      return authResponse;
    } catch (error) {
      Vue.$log.debug(error);
      captureException(error);

      return false;
    }
  }

  async checkAuth() {
    const { key: method, type } = this.methods.IsAuthorized;

    const params = {
      service: this.service,
      method,
    };

    try {
      ({ data: this.authorized } = await this.client({ params }));
    } catch (error) {
      Vue.$log.debug(error);
      captureException(error);
      return false;
    }

    return this.authorized;
  }

  // eslint-disable-next-line class-methods-use-this
  uploadVideoErrorHandler(error) {
    if (error) {
      const { config, request, response, isAxiosError } = error;
      if (isAxiosError) {
        if (typeof response !== 'undefined') {
          let { data } = response;
          let { type, message } = data;

          if ([ErrorType.WS_ERROR, ErrorType.SERVICE_ERROR].includes(type) && message === WsErrorType.PERMISSION_DENIED) {
            return { error: { msg: 'You can\'t upload this video to Twitter' } };
          }
        }
      }
    }
    return 'LoginForm Error';
  }

  async uploadVideo(vid) {
    const { key: method, type } = this.methods.MediaUpload;
    let { params: payload } = this.methods.MediaUpload;

    const params = {
      service: this.service,
      method,
    };

    payload.MediaID = vid;

    const dataset = jsonToFormData(payload);

    try {
      const { data, error } = await this.client({
        params,
        data: dataset,
        method: type,
        errorHandler: this.uploadVideoErrorHandler,
      });

      if (data) {
        return data;
      }
      if (error) {
        return error;
      }
    } catch (error) {
      Vue.$log.debug(error);
      captureException(error);

      return false;
    }
  }

  async updateStatus(status, media) {
    const { key: method, type } = this.methods.StatusUpdate;
    let { params: payload } = this.methods.StatusUpdate;

    const params = {
      service: this.service,
      method,
    };

    payload.status = status;
    payload.media_ids = media.media_id_string;

    const data = jsonToFormData(payload);

    try {
      const { data: updated } = await this.client({ params, data, method: type });
      return updated;
    } catch (error) {
      Vue.$log.debug(error);
      captureException(error);
      return false;
    }
  }
}
