<template>
<v-progress-circular v-bind="$attrs">{{ timerCount }}</v-progress-circular>
</template>


<script>
export default {
  name: 'Timer',

  props: {
    // Value in seconds
    value: {
      type: Number,
      default: 0,
      validator(value) {
        return value >= 0;
      },
    },
  },

  data() {
    return {
      timerCount: this.value,
    };
  },

  methods: {
    watchTimerCount(val) {
      if (val > 0) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      } else {
        this.$emit('timer:finish');
      }
    },
  },


  watch: {
    timerCount: {
      handler: 'watchTimerCount',
      immediate: true,
    },
  },
};
</script>
