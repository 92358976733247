/* eslint-disable brace-style */
import get from 'lodash/get';
import { Enums, Providers, Classificators } from '@flightscope/baseball-stats';
import { UnitsConverter, conversionResult } from '@flightscope/units-converter';
import { PlayerModel } from '@/models/PlayerModel';
import { Player } from '@/models/orm/Hierarchy';

const playOutcomeFinder = (_pitchCall, _plateAppearanceResult, enumObj, index, all) => {
  const { pitchCall, plateAppearanceResult } = enumObj;

  return (!pitchCall || _pitchCall === pitchCall)
    && (!plateAppearanceResult || _plateAppearanceResult === plateAppearanceResult);
};

class ActionCommentFormatter {
  /**
   *
   * @param {String} sportType
   * @param {UnitsConverter} unitsConverter
   * @param {Providers.EnumValueProvider} enumValueProvider
   * @param {String} eol
   */
  constructor(sportType, unitsConverter, enumValueProvider, eol) {
    if (typeof (sportType) !== 'string') {
      throw new TypeError('sport must be an string');
    }

    if (typeof (eol) === 'undefined') {
      this.eol = '\n';
    }
    else if (typeof (eol) !== 'string') {
      throw new TypeError('eol must be an string');
    }
    else {
      this.eol = eol;
    }

    if (typeof (enumValueProvider) === 'undefined') {
      this.enumValueProvider = Providers.EnumValueProvider;
    }
    else if (!(enumValueProvider instanceof Providers.EnumValueProvider)) {
      throw new TypeError('enumValueProvider must be an instance inheriting from Providers.EnumValueProvider class');
    }
    else {
      this.enumValueProvider = enumValueProvider;
    }

    if (!(unitsConverter instanceof UnitsConverter)) {
      throw new TypeError('unitsConverter must be an instance inheriting from UnitsConverter class');
    }
    else {
      this.unitsConverter = unitsConverter;
    }

    this.sportType = this.enumValueProvider.getValue(
      sportType, Enums.SportType, Enums.SportType.Unknown.key, false, 'key'
    );

    if (this.sportType === Enums.SportType.Unknown.key) {
      throw new Error('sportType not supported');
    }
  }

  getComment(pitch, hit, pitcher, batter, overrideUnitsSystem) {
    const lines = [];
    let pitcherLine = '';
    let batterLine = '';

    let pitchData = get(pitch, Enums.ResultData.Data.key, null);
    let hitData = get(hit, Enums.ResultData.Data.key, null);

    if (pitcher instanceof PlayerModel || pitcher instanceof Player) {
      pitcherLine += `${this.getPlayerName(pitcher, 'Pitcher')} throws `;
    } else if (pitchData) {
      pitcherLine += 'Pitcher throws ';
    }
    if (pitchData) {
      if (pitchData[Enums.PitchData.Classification.key]) {
        pitcherLine += this.formatPitchTypeName(pitch);
      }

      if (pitchData[Enums.PitchData.Speed.key]) {
        const pitchSpeed = this.formatPitchSpeed(pitch, overrideUnitsSystem, 0);
        if (pitchSpeed) {
          pitcherLine += `, ${pitchSpeed}`;
        }
      }

      if (pitchData[Enums.PitchData.Spin.key]) {
        const pitchSpin = this.formatPitchSpin(pitch, overrideUnitsSystem, 0);
        if (pitchSpin) {
          pitcherLine += `, ${pitchSpin}`;
        }
      }
    }
    if (pitcherLine && pitcherLine !== 'Pitcher throws ') {
      lines.push(pitcherLine);
    }

    if (batter instanceof PlayerModel || batter instanceof Player) {
      batterLine += `${this.getPlayerName(batter, 'Batter')}`;
    } else if (pitch || hit) {
      batterLine += 'Batter';
    }

    if (pitchData && pitchData[Enums.PitchData.Result.key]) {
      const pitchResult = this.formatPitchResult(pitch, hit);
      if (pitchResult) {
        batterLine += ` ${pitchResult}`;
      }
    }
    if (hitData) {
      if (hitData[Enums.HitData.ExitSpeed.key]) {
        const exitSpeed = this.formatExitSpeed(hit, overrideUnitsSystem, 0);
        if (exitSpeed) {
          batterLine += `, ${exitSpeed}`;
        }
      }

      if (hitData[Enums.HitData.LaunchV.key]) {
        const launchV = this.formatVLaunchAngle(hit, overrideUnitsSystem, 0);
        if (launchV) {
          batterLine += `, ${launchV}`;
        }
      }

      if (hitData[Enums.HitData.CarryDistance.key]) {
        const carryDist = this.formatCarryDistance(hit, overrideUnitsSystem, 0);
        if (carryDist) {
          batterLine += `, ${carryDist}`;
        }
      }
    }

    if (batterLine && batterLine !== 'Batter') {
      lines.push(batterLine);
    }

    return this.getHeader(pitch, hit, pitcher, batter, overrideUnitsSystem)
      .concat(lines, this.getFooter(pitch, hit, pitcher, batter, overrideUnitsSystem))
      .join(this.eol);
  }

  // eslint-disable-next-line class-methods-use-this
  getPlayerName(player, fallback) {
    if (player.DisplayName && player.DisplayName !== '-') {
      return player.DisplayName;
    }

    return player.FormattedName || fallback;
  }

  // eslint-disable-next-line class-methods-use-this
  getHeader(pitch, hit, pitcher, batter, overrideUnitsSystem) {
    // see https://docs.google.com/document/d/1BcqJaiTjFknNVeYbFnw9zA0QivurPj_wSk3rpMHgbDs/edit?disco=AAAAaMnUau0
    switch (this.sportType) {
      case Enums.SportType.Baseball.key:
        return [`Watch video from my game recorded on ${window.origin} - @FlightScopeBSBL`];

      case Enums.SportType.Softball.key:
        return [`Watch video from my game recorded on ${window.origin} - @FlightScopeSTBL`];

      default:
        return [`Watch video from my game recorded on ${window.origin}`];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getFooter(pitch, hit, pitcher, batter, overrideUnitsSystem) {
    return [];
  }

  getPitchTypeEnum() {
    switch (this.sportType) {
      case Enums.SportType.Baseball.key:
        return Enums.BaseballPitchType;

      case Enums.SportType.Softball.key:
        return Enums.SoftballPitchType;

      default:
        throw Error('Unsupported sport type, cannot determine pitch type enum');
    }
  }

  formatPitchTypeName(pitch) {
    const pitchTypeEnum = this.getPitchTypeEnum();
    const pitchName = this.enumValueProvider.getValue(
      pitch[Enums.ResultData.Data.key][Enums.PitchData.Classification.key],
      pitchTypeEnum, pitchTypeEnum.Unidentified.key, false
    );

    if (!pitchName || pitchName.key === pitchTypeEnum.Unidentified.key) {
      return 'pitch';
    }

    return pitchName.name;
  }

  pitchResultFormatter(pitch) {
    let pitchResult = this.enumValueProvider.getValue(
      pitch[Enums.ResultData.Data.key][Enums.PitchData.Result.key],
      Enums.PitchResult,
    );

    if (Enums.PitchResultGroup.outs.results.includes(pitchResult.key)) {
      return `is out - ${pitchResult.name}`;
    }
    if (Enums.PitchResultGroup.hits.results.includes(pitchResult.key)) {
      return `makes a hit - ${pitchResult.name}`;
    }
    if (Enums.PitchResultGroup.whiffs.results.includes(pitchResult.key)) {
      return `misses - ${pitchResult.name}`;
    }
    if (Enums.PitchResultGroup.swings.results.includes(pitchResult.key)) {
      return `swings - ${pitchResult.name}`;
    }
    if (Enums.PitchResultGroup.balls.results.includes(pitchResult.key)) {
      return `called ${pitchResult.name}`;
    }
    return null;
  }

  playOutcomeFormatter(_pitchCall, _plateAppearanceResult) {
    let playOutcome = Object.values(Enums.PitchResultCombined).find(
      playOutcomeFinder.bind(this, _pitchCall, _plateAppearanceResult),
    );

    if (playOutcome) {
      if (Enums.PitchResultCombinedGroup.BaseHits.results.includes(playOutcome.key)) {
        return `hits a ${playOutcome.name}`;
      }
      if (Enums.PitchResultCombinedGroup.BatterSafe.results.includes(playOutcome.key)) {
        return `reaches base, ${playOutcome.name}`;

      }
      if (Enums.PitchResultCombinedGroup.BatterOut.results.includes(playOutcome.key)) {
        return `is out, ${playOutcome.name}`;

      }
      if (Enums.PitchResultCombinedGroup.NoResult.results.includes(playOutcome.key)) {
        const swing = Classificators.PitchCall.classifiesAsSwing(_pitchCall);
        const whiff = Classificators.PitchCall.classifiesAsWhiff(_pitchCall);

        if (whiff) {
          return  `misses - ${playOutcome.name}`;
        }

        if (swing) {
          return  `makes a swing - ${playOutcome.name}`;
        }

        return `- ${playOutcome.name}`;
      }
    }

    return null;
  }

  formatPitchResult(pitch, hit) {
    const pitchResult = get(pitch, [Enums.ResultData.Data.key, Enums.PitchData.Result.key]);

    if (pitchResult && pitchResult !== Enums.PitchResult.Unidentified.key) {
      let out = this.pitchResultFormatter(pitch);

      if (out) return out;
    }

    const pitchCall = get(pitch, [Enums.ResultData.Data.key, Enums.ScoringData.PitchCall.key]);
    const plateAppearanceResult = get(pitch, [Enums.ResultData.Data.key, Enums.ScoringData.PlateAppearanceResult.key]);

    if (pitchCall || plateAppearanceResult) {
      let out = this.playOutcomeFormatter(pitchCall, plateAppearanceResult);

      if (out) return out;
    }

    if (hit && hit[Enums.ResultData.Data.key]) {
      return 'makes contact';
    }

    // https://docs.google.com/document/d/1BcqJaiTjFknNVeYbFnw9zA0QivurPj_wSk3rpMHgbDs/edit#
    // TODO - pass session type to make it even better
    return null;
  }

  formatPitchSpeed(pitch, overrideUnitsSystem, rounding) {
    const converted = this.convertNumericValue(pitch, Enums.PitchData.Speed.key, Enums.PitchData.Speed.type, overrideUnitsSystem);

    if (!(converted instanceof conversionResult) || !converted.hasValue()) {
      return null;
    }

    return converted.formatWithSymbol('-', rounding || 0);
  }

  formatPitchSpin(pitch, overrideUnitsSystem, rounding) {
    const converted = this.convertNumericValue(pitch, Enums.PitchData.Spin.key, Enums.PitchData.Spin.type, overrideUnitsSystem);

    if (!(converted instanceof conversionResult) || !converted.hasValue()) {
      return null;
    }

    return converted.formatWithSymbol('-', rounding || 0);
  }

  formatExitSpeed(hit, overrideUnitsSystem, rounding) {
    const converted = this.convertNumericValue(hit, Enums.HitData.ExitSpeed.key, Enums.HitData.ExitSpeed.type, overrideUnitsSystem);

    if (!(converted instanceof conversionResult) || !converted.hasValue()) {
      return null;
    }

    return converted.formatWithSymbol('-', rounding || 0);
  }

  formatVLaunchAngle(hit, overrideUnitsSystem, rounding) {
    const converted = this.convertNumericValue(hit, Enums.HitData.LaunchV.key, Enums.HitData.LaunchV.type, overrideUnitsSystem);

    if (!(converted instanceof conversionResult) || !converted.hasValue()) {
      return null;
    }

    return converted.formatWithSymbol('-', rounding || 1);
  }

  formatCarryDistance(hit, overrideUnitsSystem, rounding) {
    // https://docs.google.com/document/d/1BcqJaiTjFknNVeYbFnw9zA0QivurPj_wSk3rpMHgbDs/edit#bookmark=id.czmap0xz7uvj
    const converted = this.convertNumericValue(hit, Enums.HitData.CarryDistance.key, Enums.HitData.CarryDistance.type, overrideUnitsSystem);

    if (!(converted instanceof conversionResult) || !converted.hasValue()) {
      return null;
    }

    return converted.formatWithSymbol('-', rounding || 1);
  }

  convertNumericValue(result, propertyKey, type, overrideUnitsSystem) {
    const valueSi = typeof (result) === 'object' && typeof (result[Enums.ResultData.Data.key]) !== 'undefined'
      ? result[Enums.ResultData.Data.key][propertyKey] ?? NaN
      : NaN;

    const converted = this.unitsConverter.convertType(valueSi, type, overrideUnitsSystem);
    return converted;
  }
}

export default ActionCommentFormatter;
