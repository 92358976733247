import { render, staticRenderFns } from "./VideoBox.vue?vue&type=template&id=ddd1b7c6&scoped=true"
import script from "./VideoBox.js?vue&type=script&lang=js&external"
export * from "./VideoBox.js?vue&type=script&lang=js&external"
import style0 from "./VideoBox.vue?vue&type=style&index=0&id=ddd1b7c6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddd1b7c6",
  null
  
)

export default component.exports