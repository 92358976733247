// @ts-check

import { mapGetters } from 'vuex';

import ShareBox from './ShareBox.vue';
import VideoBox from './VideoBox.vue';
import { videoMapper } from '../helpers/videoMapper';

const videoFilter = (vid) => vid;

export default {
  name: 'ActionBottomSheet',

  components: {
    ShareBox,
    VideoBox,
  },

  inject: ['repo'],

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    disableTableLink: {
      type: Boolean,
      default: false,
    },

    disableActionNav: {
      type: Boolean,
      default: false,
    },

    actions: Array,

    sortedActions: Array,

    selectedAction: Object,

    player: Object,

    thumbUrl: {
      type: String,
      default: '/img/temp/twitter-thumb.jpg',
    },

    videoFilter: {
      type: Function,
      default: videoFilter,
    },

    videoMapper: {
      type: Function,
      default: videoMapper,
    },

    mapActionToVideoDialog: {
      type: Function,
      default(action) {
        const notDeletedMedia = action.videos
          .filter(this.videoFilter)
          .map(this.videoMapper);

        return {
          id: action.id,
          player: this?.player?.Id ? this.player : null,
          results: action.results,
          media: notDeletedMedia,
        };
      },
    },

    deletedVideoIds: Array,

    actionKey: {
      type: [String,Symbol],
      default: 'id',
    },
    mediaKey: {
      type: [String,Symbol],
      default: 'videos',
    },

    MediaRepo: {
      default() {
        return this.repo.get('media');
      },
    },
  },

  model: {
    prop: 'visible',
  },

  data() {
    return {
      sharing: false,

      deletingVideo: false,
      deletedVideo: false,
      videoIdToDelete: undefined,
    };
  },

  computed: {
    ...mapGetters(['selectedUnitsSystem']),

    selectedActionIndex() {
      if (this.$route.params.action && this.sortedActions?.length) {
        return this.sortedActions.findIndex((act) => act?.[this.actionKey] === this.$route.params.action);
      }
      return -1;
    },

    prevAction() {
      if (this.selectedActionIndex > 0) {
        return this.sortedActions[this.selectedActionIndex - 1];
      }
      return null;
    },

    prevActionLink() {
      return {
        params: {
          action: this.prevAction?.[this.actionKey],
          vid: this.prevAction?.[this.mediaKey]?.[0].id,
        },
      };
    },

    nextAction() {
      if (this.selectedActionIndex >= 0 && this.selectedActionIndex < this.sortedActions.length) {
        return this.sortedActions[this.selectedActionIndex + 1];
      }
      return null;
    },

    nextActionLink() {
      return {
        params: {
          action: this.nextAction?.[this.actionKey],
          vid: this.nextAction?.[this.mediaKey]?.[0].id,
        }
      };
    },

    videosDialogMaxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return null;
        case 'sm':
          return null;
        case 'md':
          return 800;
        case 'lg':
          return 1200;
        case 'xl':
          return 1500;
        default:
          return null;
      }
    },

    videosDialogFullscreen() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return true;
        case 'md':
          return false;
        case 'lg':
          return false;
        case 'xl':
          return false;
        default:
          return false;
      }
    },

    dialogDeleteVideo: {
      get() {
        return !!this.videoIdToDelete || this.deletedVideo;
      },
      set() {
        this.videoIdToDelete = undefined;
      }
    },

    /**
     * @typedef {Object} VideoThumb
     * @property {string} URL
     * @property {number} Width
     * @property {number} Height
     * @property {number} Bytesize
     */

    /**
     * @typedef {Object} VideoMetadata
     * @property {number} Bytesize
     * @property {number} RecordingFPS
     * @property {number} EncodingFPS
     * @property {number} PreTime
     * @property {number} PostTime
     * @property {VideoThumb[]} Thumbnails
     * },
     */

    /**
     * @typedef {Object} ActionVideo
     *  @property {number} id
     *  @property {number} ownerID
     *  @property {number} mediaTypeID
     *  @property {string} createDate
     *  @property {string} ts
     *  @property {VideoMetadata} metadata
     *  @property {string} mediaType
     *  @property {string} path
     *  @property {string} displayName
     *  @property {number} totalByteSize
     *  @property {number|null} masterMediaID
     *  @property {boolean} isDeleted
     *  @property {string|null} starredDate
     */

    /**
     * @type {ActionVideo|null}
     */
    selectedActionVideo() {
      const vid = this.$route.params.vid;
      if (this.selectedAction?.[this.mediaKey] instanceof Array) {
        return this.selectedAction?.[this.mediaKey].find((video) => video.id == vid);
      }
      else if (this.selectedAction?.videos instanceof Array) {
        return this.selectedAction?.videos.find((video) => video.id == this.vid);
      }

      return null;
    },

    selectedActionThumb() {
      return this.selectedActionVideo?.metadata?.Thumbnails[0].URL ?? this.thumbUrl;
    },
  },

  methods: {
    hideWhenNotSharing() {
      if (!this.sharing) {
        this.$router.push({ params: { action: null, vid: null, share: null } });
        return false;
      }
      return true;
    },

    async deleteVideo() {
      this.deletingVideo = true;
      this.deletedVideo = false;

      const { message, error } = await this.MediaRepo.deleteMedia(this.videoIdToDelete);

      if (message) {
        this.deletedVideoIds.push(this.videoIdToDelete);

        this.deletedVideo = true;
        setTimeout(() => {
          this.deletedVideo = false;
        }, 3000);
      }

      this.deletingVideo = false;
      this.videoIdToDelete = undefined;
    },
  },
};
