
export function videoMapper(vid) {
  const date = new Date(0);
  date.setSeconds(
    (vid.metadata?.PreTime ?? 0) + (vid.metadata?.PostTime ?? 0)
  );

  const thumb = vid?.metadata?.Thumbnails?.find(t => t.URL)?.URL;

  return {
    id: vid.id,
    Url: vid.path,
    thumb,
    durationFormatted: date.toISOString().substr(15, 4),
    ownerID: vid.ownerID,
  };
}
