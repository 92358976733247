<script>
import get from 'lodash/get';

export default {
  name: 'VideoPlayer',
  props: {
    video: {
      type: Object,
      required: true,
    },
    muted: {
      type: Boolean,
      required: false,
      default: false,
    },
    loop: {
      type: Boolean,
      required: false,
      default: false,
    },
    nonResponsive: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
    },
    pathKey: {
      type: String,
      default: 'Url',
    },
  },
  data() {
    return {
      autoplay: true,
      controls: true,
      controlslist: 'nodownload',
      disablePIP: true,
      width: 720,
      aspectRatio: 16 / 9,
    };
  },
  computed: {
    height() {
      return this.width * this.aspectRatio;
    },
    videoPath() {
      return get(this.video, [this.pathKey], '');
    },

    poster() {
      return this.video?.thumb || this.video?.metadata?.Thumbnails?.find(t => t.URL)?.URL;
    },
  },
  methods: {
    getVideoDimensions(e) {
      this.aspectRatio = e.target.videoWidth / e.target.videoHeight;
    },
  },
};
</script>

<template>
  <v-responsive
    :aspect-ratio="aspectRatio"
    class="video-player"
    v-bind="$attrs"
    content-class="d-flex align-center justify-center"
    v-if="!nonResponsive"
  >
    <video
      :poster="poster"
      :src="videoPath"
      :muted="muted"
      :loop="loop"
      :autoplay="autoplay"
      :controls="controls"
      :controlslist="controlslist"
      :disablePictureInPicture="disablePIP"
      @loadedmetadata="getVideoDimensions"
    ></video>
  </v-responsive>
  <video
    v-else
    :poster="poster"
    :src="videoPath"
    :muted="muted"
    :loop="loop"
    :autoplay="autoplay"
    :controls="controls"
    :controlslist="controlslist"
    :disablePictureInPicture="disablePIP"
    @loadedmetadata="getVideoDimensions"
    :style="styles"
  ></video>
</template>


<style module>
.vidWrapper {
  width: auto; /* Play with this value */
  height: calc(90vh - 58px - 52px - 20px - 48px); /* Play with this value */
  max-height: 100%;
  font-size: 0;
  text-align: center;
}
.vidWrapper::before {
  content: '';
  width: 1px;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-left: -1px;
}

.vidWrapper video {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
}
video {
  max-width: 100%;
  max-height: 100%;
}
</style>
