import ActionCommentFormatter from '@/formatters/actionCommentFormatter';
import TwitterShare from './TwitterShare';
import { unitsConverter } from '@/plugins/converter';

import { PlayerModel } from '@/models/PlayerModel';

import Timer from '@/modules/link-handler/components/Timer.vue';
import InlineLoader from '@/modules/link-handler/components/InlineLoader.vue';

export default {
  name: 'ShareBox',

  inject: ['repo'],

  props: {
    value: {
      type: Boolean,
    },
    vid: {
      type: [String, Number],
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
    thumbUrl: {
      type: String,
    },

    btnText: {
      type: String,
      default: 'Upload video & share',
    },

    unitSystem: {
      type: String,
      required: false,
    },

    player: {
      type: PlayerModel,
    },

    PlayerRepo: {
      default() {
        return this.repo.get('players');
      },
    },
  },

  components: {
    Timer,
    InlineLoader,
  },

  data() {
    return {
      shareClient: null,
      actionCommentFormatter: null,
      uploading: false,

      alertType: 'info',
      alertMsg: '',

      redirectTo: null,
      redirectTime: 10,
      showRedirectLoader: true,
    };
  },

  asyncComputed: {
    actionPlayers: {
      async get() {
        if (!this.action || !this.actionCommentFormatter) {
          return '';
        }
        let batter = null;
        let pitcher = null;
        let tempBatter;
        let tempPitcher;

        const { pitch, hit } = this.action.results;

        // try to fetch pitcher...
        if (pitch?.TagID) {
          if (pitch.TagID === this.player?.Id) {
            pitcher = this.player;
          }
          else {
            tempPitcher = await this.PlayerRepo.get(pitch.TagID);
            if (!tempPitcher.isDefault) pitcher = tempPitcher;
          }
        }
        else if(hit?.RelatedPitcherTagID) {
          if (hit.RelatedPitcherTagID === this.player?.Id) {
            pitcher = this.player;
          }
          else {
            tempPitcher = await this.PlayerRepo.get(hit.RelatedPitcherTagID);
            if (!tempPitcher.isDefault) pitcher = tempPitcher;
          }
        }

        // try to fetch batter...
        if (pitch?.RelatedBatterTagID) {
          if (pitch.RelatedBatterTagID === this.player?.Id) {
            batter = this.player;
          }
          else {
            tempBatter = await this.PlayerRepo.get(pitch.RelatedBatterTagID);
            if (!tempBatter.isDefault) batter = tempBatter;
          }
        }
        else if (hit?.TagID) {
          if (hit.TagID === this.player?.Id) {
            batter = this.player;
          }
          else {
            tempBatter = await this.PlayerRepo.get(hit.TagID);
            if (!tempBatter.isDefault) batter = tempBatter;
          }
        }

        return { batter, pitcher };
      },
      default: {
        batter: undefined,
        pitcher: undefined,
      },
    },
    comment: {
      async get() {
        if (!this.action || !this.actionCommentFormatter || typeof (this.actionPlayers.pitcher) === 'undefined') {
          return '';
        }
        const { pitch, hit } = this.action.results;

        const comment = this.actionCommentFormatter.getComment(
          pitch,
          hit,
          this.actionPlayers.pitcher,
          this.actionPlayers.batter,
          this.unitSystem,
        );

        return comment;
      },
      watch: ['action', 'actionCommentFormatter', 'actionPlayers'],
      default: '',
    },
  },

  computed: {
    sharing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    sharedVideo() {
      if (this.action.Media instanceof Array) {
        return this.action.Media.find((video) => video.id == this.vid);
      }
      else if (this.action.videos instanceof Array) {
        return this.action.videos.find((video) => video.id == this.vid);
      }

      this.$log.warn(`unable to match shared video ${this.vid} on the action item`);
      return null;
    },

    sharedVideoThumb() {
      return this.thumbUrl;
    },
  },

  mounted() {
    this.shareClient = new TwitterShare();
    this.actionCommentFormatter = new ActionCommentFormatter(process.env.VUE_APP_SPORT_TYPE, unitsConverter);
  },

  methods: {
    redirect(link, target = '_blank') {
      window.open(link, target);
    },

    getRedirectUrl() {
      // will have to return here after authorization
      return `${window.location.origin}${this.$route.fullPath}`;
    },

    async shareIntent() {
      this.sharing = true;

      this.alertMsg = 'Checking Twitter authorization';

      const authorized = await this.shareClient.checkAuth();

      if (!authorized) {
        // will have to return here after authorization
        let redirectUrl = this.getRedirectUrl();

        const twitterRedirect = await this.shareClient.getAuthorizeUrl(redirectUrl);

        if (twitterRedirect) {
          this.alertMsg =
            'You will be redirected to Twitter for authorization in a moment.\nAfter coming back please upload again.';
          this.redirectTo = twitterRedirect;
        } else {
          this.alertMsg = 'You are not authorized, please reload the page and try again.';
          this.alertType = 'error';
          this.sharing = false;
        }

        return;
      }

      this.alertMsg = 'Uploading selected video to Twitter';
      const uploaded = await this.shareClient.uploadVideo(this.sharedVideo.id);

      // eslint-disable-next-line camelCase
      if (uploaded?.media_id) {
        this.alertMsg = 'Updating status';
        const updated = await this.shareClient.updateStatus(this.comment, uploaded);

        if (updated?.data?.id) {
          this.alertMsg = `Status updated - check it <a target="_blank" href="https://twitter.com/FlightScope/status/${updated?.data?.id}">here</a>`;
          this.alertType = 'success';
        } else {
          this.alertMsg = 'Something went wrong during the process';
          this.alertType = 'error';
        }
      }

      this.sharing = false;
    },
  },
};
